.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@media only screen and (max-device-width: 2000px) {
  html {
    font-size: 16px
  }
}

/* ----------- iPhone 6+ / iPhone X----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  html {
    font-size: 12px !important
  }
}

/* ----------- iPad ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  html {
    font-size: 13px !important
  }
}